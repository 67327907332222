import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@web-solutions/module-analytics';
import { stringifyUrlParams } from '@web-solutions/core/utils/url-sync';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { setAlreadyCreatedAccount } from '../store/actions';

import { useBaseProcesses } from './use-base-processes';

export type InitialData = {
  email: string,
  password: string,
  confirm_password: string,
}

export const useCreateAccount = (email: string, linkAuthResult?: any) => {
  const dispatch = useDispatch();

  const {
    data,
    validation,
    handleChange,
  } = useBaseProcesses(email);

  const [erorPopup, setErorPopup] = useState({
    show: false,
    provider: '',
  });

  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);

  useEffect(() => {
    Analytics.trackEvent('firebase_auth', EVENT_ACTION.OPEN);
  }, []);

  useEffect(() => {
    if (linkAuthResult instanceof Error) {
      // @ts-ignore
      const code = linkAuthResult?.code;
      Analytics.trackEvent('firebase_auth', 'apple_error', { error: linkAuthResult?.message, code, provider: 'apple' });
      setErorPopup({ show: true, provider: 'apple' });
    } else if (linkAuthResult) {
      if (linkAuthResult?.email) {
        Analytics.trackEvent('firebase_auth', 'apple_success', { provider: 'apple' });
        stringifyUrlParams({ alreadyCreatedAccount: null });
        dispatch(setAlreadyCreatedAccount(true));
        setIsOpenSuccessPopup(true);
      } else {
        Analytics.trackEvent('firebase_auth', 'apple_error', { code: 'no-email', provider: 'apple' });
        setErorPopup({ show: true, provider: 'apple' });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkAuthResult]);

  return {
    erorPopup,
    data,
    validation,
    isOpenSuccessPopup,
    handleChange,
    setErorPopup,
    setIsOpenSuccessPopup
  }
}