import classNames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { ProductDetails } from "core/store/billing/selectors";
import { CardFormLayout } from "core/payment/constants";
import { Subscription } from "core/interfaces/billing";
import { PaymentFormOptions } from "core/payment/types";
import { selectPaymentSystem } from 'core/store/billing/selectors';

import classes from '../style.module.scss';

interface Props {
  isSimpleView: boolean;
  activeProduct: ProductDetails;
  cardFormLayout: CardFormLayout;
  options: PaymentFormOptions;
  showCardForm: boolean;
  injectedCard: boolean;
  onSubmit: (formData: any) => Promise<void> | undefined;
  onSuccess: (purchase: Subscription) => void;
  onError: (error: any) => void;
  onApplePayClick: () => void;
  onApplePaySubmit: (formData: any) => Promise<void>;
  onGooglePayClick: () => void;
  onGooglePaySubmit: (formData: any) => Promise<void>;
  onUserInfoChange: (formData: any) => void;
  onCheckPrepaid?: () => void;
}

export const useFormProps = ({
  isSimpleView,
  activeProduct,
  cardFormLayout,
  options,
  showCardForm,
  injectedCard,
  onSubmit,
  onSuccess,
  onError,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onUserInfoChange,
  onCheckPrepaid,
}: Props) => {
  const paymentSystem = useSelector(selectPaymentSystem);

  const formWrapStyle = classNames(classes.formWrap, {
    [classes.formWrapOpen]: showCardForm || injectedCard,
  });

  const expandWrapStyle = classNames(classes.expandWrap, {
    [classes.expand]: showCardForm || injectedCard,
  });

  const color = useMemo(() => {
    const root = document.querySelector(':root') as HTMLElement;

    return getComputedStyle(root).getPropertyValue('--injectedPaymentPrimaryColor');
  }, [])

  const cardFormProps = useMemo(() => ({
    isSimpleView,
    product: activeProduct,
    color,
    options,
    paymentSystem,
    formClassName: 'payment-form',
    fieldClassName: classNames('form-field', { 'form-field-compact': cardFormLayout === CardFormLayout.COMPACT }),
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
    onCheckPrepaid,
  }), [
    isSimpleView,
    color,
    activeProduct,
    options,
    cardFormLayout,
    paymentSystem,
    onSubmit,
    onSuccess,
    onError,
    onApplePayClick,
    onApplePaySubmit,
    onGooglePayClick,
    onGooglePaySubmit,
    onUserInfoChange,
    onCheckPrepaid,
  ]);


  return {
    cardFormProps,
    expandWrapStyle,
    formWrapStyle,
  }
}