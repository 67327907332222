import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import React, { useCallback, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import type { ThemeMode } from '@web-solutions/core/interfaces/theme';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { useNavigation } from '@web-solutions/base-app/hooks/use-navigation';

import { setHandImg, setPalmReport, savePalmData, setPalmWithLines } from '../store/actions';

import { createKeypoints, createLines, randomPrediction } from './utils';

import Welcome from './components/welcome';
import Upload from './components/upload';
import Capture from './components/capture';
import Analyzing from './components/analyzing';
import ErrorPopup from './components/error-popup';
import * as detector from './back-detector';

import { ROUTES, ERRORS, LoadMethod, type ImgData } from './constants';

import classes from './style.module.scss';

const tKey = 'palm_reading';

const captureEventCategory = 'quiz_palm_reading_capture';
const uploadEventCategory = 'quiz_palm_reading_upload';
const analyzingEventCategory = 'quiz_palm_reading_analyzing';

export type Theme = {
  isDarkTheme: boolean,
  isLightTheme: boolean,
  theme: ThemeMode
}

interface Props {
  magicDurationLoad: number;
  cameraInitTimeout: number;
  isPalmDisclaimerVisible: boolean;
  welcomeComponent?: React.ReactNode | null,
  theme?: Theme;
  onSkip: () => void;
  onDone: () => void;
}

const PalmReadingModule: React.FC<Props> = ({
  magicDurationLoad,
  cameraInitTimeout,
  isPalmDisclaimerVisible,
  theme,
  welcomeComponent,
  onSkip,
  onDone
}) => {
  const { palmReadingLineDrawingMethod } = useRemoteConfig();
  const dispatch = useDispatch();
  const navigateTo = useNavigation();
  const [error, setError] = useState('');
  const [animation, setAnimation] = useState(false);

  const handlePhotoTakeSuccess = useCallback(({ img, width, height, ratio }: ImgData, type: LoadMethod) => {
    dispatch(setPalmReport(null));
    dispatch(setHandImg({ img, width, height, ratio }));
    navigateTo(ROUTES.ANALYZING, { replace: true, state: { type } });
    setAnimation(true);
    Analytics.trackEvent(analyzingEventCategory, EVENT_ACTION.OPEN);

    return detector.process(img, { linePointsAmount: 10 }, palmReadingLineDrawingMethod)
      .then((r: any) => {
        const keypoints = createKeypoints(r.hand_points);
        const lines = createLines(r.original_lines);

        let error;

        if (!keypoints?.length && _isEmpty(lines)) {
          error = ERRORS.NO_HAND;
        }

        if (error) {
          setAnimation(false);
          throw Error(error);
        } else {
          const omitedPalmData = _omit(r, ['image']);

          dispatch(savePalmData(img, {
            ...omitedPalmData,
            lines,
            keypoints,
            dims: { width, height, ratio },
            overviewHandResults: {
              love: randomPrediction(),
              health: randomPrediction(),
              wisdom: randomPrediction(),
              career: randomPrediction(),
            }
          }))
          dispatch(setHandImg({ img, keypoints, basePoint: r.hand_points?.[0], lines, width, height, ratio }));

          if (r.image) {
            dispatch(setPalmWithLines(`data:image/png;base64,${r.image}`));
          }

        }
      });
  }, [dispatch, navigateTo]);

  const handleCaptureSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.SUCCESS);

    handlePhotoTakeSuccess(img, LoadMethod.CAPTURE)
      .catch((err) => {
        Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
        if (err.message === ERRORS.NO_HAND) {
          setError(t(`${tKey}.errors.${err?.message}`));
          navigateTo(ROUTES.CAPTURE, { replace: true });
        } else {
          dispatch(setHandImg({}));
          onSkip();
        }
      });
  }, [handlePhotoTakeSuccess, navigateTo, dispatch, onSkip]);

  const handleUploadSuccess = useCallback((img: ImgData) => {
    Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.SUCCESS);
    handlePhotoTakeSuccess(img, LoadMethod.UPLOAD)
      .catch((err) => {
        Analytics.trackEvent(uploadEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
        if (err.message === ERRORS.NO_HAND) {
          navigateTo(ROUTES.UPLOAD, { replace: true, state: { src: img?.img } });
        } else {
          dispatch(setHandImg({}));
          onSkip();
        }
      });
  }, [handlePhotoTakeSuccess, navigateTo, dispatch, onSkip]);

  const handleCaptureError = useCallback((err: any) => {
    console.warn(err);
    Analytics.trackEvent(captureEventCategory, EVENT_ACTION.ERROR, { error: err?.message });
    onSkip();
  }, [onSkip]);

  const handleAnalyzingFinish = useCallback(() => {
    setAnimation(false);
  }, []);

  const handleAnalyzingDone = useCallback((withoutDelay?: boolean) => {
    Analytics.trackEvent(analyzingEventCategory, EVENT_ACTION.SUCCESS);
    if (withoutDelay) {
      onDone()
    } else {
      setTimeout(onDone, 2000);
    }
  }, [onDone]);

  const handleErrorPopupClose = useCallback(() => {
    setError('');
  }, [setError]);

  const handleSkipClick = useCallback(() => {
    setError('');
    Analytics.trackEvent(captureEventCategory, 'try_later');
    dispatch(setHandImg({}));
    onSkip()
  }, [dispatch, onSkip]);

  const handleRetakeClick = (type: LoadMethod) => {
    const isCaptureLoadMethod = type === LoadMethod.CAPTURE;
    const event = isCaptureLoadMethod ? captureEventCategory : uploadEventCategory;
    const rout = isCaptureLoadMethod ? ROUTES.CAPTURE : ROUTES.UPLOAD;
    navigateTo(rout, { replace: true });
    Analytics.trackEvent(event, 'open');
  };

  return (
    <div className={classes.container}>
      <Routes>
        <Route path={ROUTES.UPLOAD} element={
          <Upload
            onSuccess={handleUploadSuccess}
          />}
        />
        <Route path={ROUTES.CAPTURE} element={
          <Capture
            cameraInitTimeout={cameraInitTimeout}
            onError={handleCaptureError}
            onSuccess={handleCaptureSuccess}
          />
        } />
        <Route path={ROUTES.ANALYZING} element={
          <Analyzing
            magicDurationLoad={magicDurationLoad}
            onFinish={handleAnalyzingFinish}
            onDone={handleAnalyzingDone}
            onRetakeClick={handleRetakeClick}
          />
        } />
        <Route path={'*'} element={
          <Welcome
            isPalmDisclaimerVisible={isPalmDisclaimerVisible}
            component={welcomeComponent}
            theme={theme}
          />
        } />
      </Routes>
      {animation && <div className={classes.animation}></div>}
      <ErrorPopup
        theme={theme}
        visible={!!error}
        title={error}
        onClose={handleErrorPopupClose}
        onSkipClick={handleSkipClick}
      />
    </div>
  );
};

export default PalmReadingModule;