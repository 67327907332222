import {
  getAuth,
  signInWithCustomToken,
  updateEmail,
  linkWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

import request from '@web-solutions/core/utils/network';

const authDeviceId = (idfm: string) =>
  request.post('/auth', { device_id: idfm, });

export const authFirebase = (idfm: string) =>
  request.post<any, { custom_token: string }>('/auth/firebase/create', { uid: idfm, })
    .then(({ custom_token }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred) => userCred.user.getIdToken())
    .then((token) => request.post('/auth/firebase/auth', { token, }));

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm));

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  return linkWithRedirect(getAuth().currentUser!, provider);
}

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  return linkWithRedirect(getAuth().currentUser!, provider);
}

export const updateUserEmail = (email: string) =>
  DeviceProps.getIDFM()
    .then(authFirebase)
    .then(() => updateEmail(getAuth().currentUser!, email));