import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useResolvedPath } from 'react-router-dom'

import { Button, Preloader, Text } from '@web-solutions/core/ui-elements';

import Analytics from '@web-solutions/module-analytics';
import { EVENT_ACTION } from '@web-solutions/core/constants/general';
import { Verify3dsModal } from '@web-solutions/core/containers/verify-3ds';
import ErrorPopup from '@web-solutions/core/payment/components/error-popup';
import { useHeight } from '@web-solutions/core/hooks/use-height';

import { useRemoteConfig } from '../../../../../core/hooks/use-remote-config';

import { Header } from '../../components/header';
import { SuccessModal } from '../../components/success-modal';

import { goAppLink, useSliceDispatch, changePlan, selectProduct } from '../../slice';

import { t, TM } from '../../../localization';

import { useOffer } from '../../hooks/use-offer';
import { useManageContext } from '../../hooks/use-manage-context';
import { useNavigateManage } from '../../hooks/use-navigate-next-screen';
import { ROUTES } from '../../constants/routes';

import classes from './style.module.scss';

const tKey = 'manage.offer_cheap_product';

const category = "manage_offer_cheap_product"

export const OfferCheapProductPage: React.FC = () => {
  const { pathname } = useResolvedPath('');
  const { mngSubOfferCheapProductPage } = useRemoteConfig();
  const currentProduct = useSelector(selectProduct);
  const product = mngSubOfferCheapProductPage[currentProduct?.currency!] ?? mngSubOfferCheapProductPage.USD
  const price = product?.price
  const productId = product?.productId
  const { backEnabled, subtitle, type } = mngSubOfferCheapProductPage;
  const { navigateNextScreen, navigatePrevSceen } = useNavigateManage();
  const { offerCheapProductContent } = useManageContext();
  const dispatch = useSliceDispatch();
  const { period, offer } = useOffer(productId);

  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [verifyUrl, setVerifyUrl] = useState('');

  const containerHeight = useHeight();

  const isSingleOffer = pathname === ROUTES.SINGLE_OFFER_CHEAP_PRODUCT

  const isTopImageType = type === 'top_image';

  useEffect(() => {
    Analytics.trackEvent(category, EVENT_ACTION.OPEN, { type });
  }, [])

  const handleCloseClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.CLOSE);
    if (isSingleOffer) {
      dispatch(goAppLink());
    } else {
      navigatePrevSceen();
    }
  };

  const handleRejectClick = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SKIP);
    navigateNextScreen();
  }

  const handlePurchaseClick = () => {
    Analytics.trackEvent(category, 'change_plan');
    setPending(true);
    dispatch(changePlan({ productId, isNewSubscription: true }))
      .unwrap()
      .then((r: any) => {
        const url = r?.verify_url
        if (url) {
          setVerifyUrl(url);
        } else {
          setVerifyUrl('');
          handleSuccess();
        }
      })
      .catch((e: any) => {
        Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: e?.message, code: e?.code, });
        setError(e?.message || t('manage.error.text'));
        setStatusCode(e?.code);
      })
      .finally(() => {
        setPending(false);
      })
  }

  const handleSuccess = () => {
    Analytics.trackEvent(category, EVENT_ACTION.SUCCESS);
    setVerifyUrl('');
    setSuccess(true)
  }

  const handleFail = () => {
    Analytics.trackEvent(category, EVENT_ACTION.ERROR, { message: '3DS error' });
    setVerifyUrl('');
    setError('3DS error');
  }

  const handleCloseErrorClick = () => {
    Analytics.trackEvent(`${category}_error_modal`, EVENT_ACTION.CLOSE);
    setError('');
    navigateNextScreen();
  }

  return (
    <div className={classes.wrap} style={{ minHeight: containerHeight }}>
      {pending && <Preloader />}
      <div className={classes.top_light} />
      <Header
        title={t(`${tKey}.title`)}
        subtitle={isTopImageType ? undefined : t(`${tKey}.text`)}
        content={isTopImageType ? undefined : offerCheapProductContent?.title}
        withBackButton={backEnabled}
        onBackClick={handleCloseClick}
      />
      {offerCheapProductContent?.content}
      <div>
        {!isTopImageType && (
          <Text className={classes.subtitle}>
            <TM k={subtitle} defaultKey={`${tKey}.trial_desc`} tOptions={{ ...offer, period, trialDays: offer?.trialDays }} />
          </Text>
        )}
        {isTopImageType && (
          <Text className={classes.price_title}>{t(`${tKey}.price_title`)}</Text>
        )}
        <Text className={classnames(
          classes.price_text,
          classes.text,
          { [classes.alt_price_text]: isTopImageType }
        )}>
          <TM k={price} defaultKey={`${tKey}.price`} tOptions={{ ...offer, period, trialPrice: offer?.trialPrice }} />
        </Text>
        {isTopImageType && (
          <Text className={classes.description}>
            <TM k={subtitle} defaultKey={`${tKey}.description`} tOptions={{ ...offer, period, trialDays: offer?.trialDays }} />
          </Text>
        )}
      </div>
      <div className={classes.buttons}>
        <Button
          onClick={handlePurchaseClick}
          type="button"
          titleClassName={classes.bottom_button}
          title={t(`${tKey}.button`)}
        />
        <Button
          onClick={isSingleOffer ? handleCloseClick : handleRejectClick}
          type="button"
          className={classes.reject_button}
          titleClassName={classes.reject_button_title}
          title={t(`${tKey}.reject_button`)}
        />
      </div>
      <SuccessModal
        category={category}
        isOpen={success}
        tKey={`${tKey}.success_modal`}
      />

      <ErrorPopup
        visible={!!error}
        onClose={handleCloseErrorClick}
        onSubmit={handleCloseErrorClick}
        buttonTitle={`OK`}
        statusCode={statusCode}
      />

      <Verify3dsModal
        verifyUrl={verifyUrl}
        onSuccess={handleSuccess}
        onFail={handleFail}
      />
    </div>
  );
};
