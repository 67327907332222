import React, { useMemo } from 'react';
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { Title, Text, LinkButton, Modal, Image, Button } from 'core/ui-elements';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { Theme } from 'core/constants/theme';
import { ProductDetails } from 'core/store/billing/selectors';
import { useThemeContext } from 'core/hooks/use-theme-context';
import { PayPalBtn } from 'core/payment/components/paypal';
import { type Subscription } from 'core/interfaces/billing';

import { ReactComponent as IconCancel } from './icons/cancel.svg';
import { IMAGES } from './images';

import classes from './style.module.scss';
import { InsufficientFunds } from './components/302';
import { InvalidData } from './components/201-208-209';
import { PaymentAmountLimit } from './components/303';
import { SubscriptionLocked } from './components/214';
import { CardIssuer } from './components/003-306-403';
import { TokenNotFound } from './components/701';
import { CardIssuerWithImage } from './components/001-002-101-213-301-310-40x-501-602';
import { CardIssuerLaw } from './components/304-305-308-408-601';
import { CardBrandNotSupported } from './components/307';
import { CardIssuerInvalidFlow } from './components/211-212-309-502-503-508-707';
import { CardIssuerEmailFooter } from './components/2xx-5xx-7xx';
import { getContentByCode } from './utils/get-content-by-code';

const ERRORS_CONTENT = {
  insufficientFunds: InsufficientFunds,
  invalidData: InvalidData,
  paymentAmountLimit: PaymentAmountLimit,
  subscriptionLocked: SubscriptionLocked,
  cardIssuer: CardIssuer,
  tokenNotFound: TokenNotFound,
  cardIssuerWithImage: CardIssuerWithImage,
  cardIssuerLaw: CardIssuerLaw,
  cardBrandNotSupported: CardBrandNotSupported,
  cardIssuerInvalidFlow: CardIssuerInvalidFlow,
  cardIssuerEmailFooter: CardIssuerEmailFooter,
}

const images = {
  'card': IMAGES.CARD_BG,
  'billing': IMAGES.BILLING_BG,
}

const images_dark = {
  'card': IMAGES.DARK_CARD_BG,
  'billing': IMAGES.DARK_BILLING_BG,
}

type Props = {
  visible: boolean;
  product?: ProductDetails;
  onClose: (isPaypal?: boolean) => void;
  onSubmit: () => void;
  statusCode?: string | number,
  withStatusCode?: boolean,
  buttonTitle?: string,
  isPrepaidCard?: boolean;
  tKey?: string,
  onSkipClick?: () => void;
} & ({
  withPayPal?: false;
} | {
  withPayPal?: true;
  onPayPalSuccess: (p: Subscription) => void;
  onPayPalError: (e: any) => void;
  onPayPalSubmit: (formData: any) => void;
})

const category = 'error_popup'

const ErrorPopup: React.FC<Props> = (props) => {

  const {
    visible,
    product,
    onClose,
    onSubmit,
    withPayPal,
    buttonTitle,
    isPrepaidCard,
    statusCode,
    tKey = 'core.subscription.error_popup',
    onSkipClick,
  } = props;

  const { errorPopup } = useRemoteConfig();
  const { theme } = useThemeContext();
  const isDark = theme === Theme.dark ? true : false;

  const code = useMemo(() => {
    const isInsufficentFunds = statusCode === '3.02' || statusCode === '302' || statusCode === 302 || statusCode === 3.02
    if (isPrepaidCard) {
      return 'prepaid';
    }
    if (statusCode && isInsufficentFunds) {
      return `302`
    } 
    else {
      return errorPopup?.type
    }
  }, [statusCode, errorPopup?.type, isPrepaidCard])

  const Component = ERRORS_CONTENT[getContentByCode(statusCode)]

  const handleClose = () => {
    onClose(withPayPal)
  }

  const handlePayPalSubmit = (formData: any) => {
    Analytics.trackEvent(category, 'payment_submit', { mode: 'paypal' });
    if (withPayPal) {
      props.onPayPalSubmit(formData)
    }
  }

  const handleSuccess = (p: Subscription) => {
    Analytics.trackEvent(category, 'payment_success', { mode: 'paypal' });
    if (withPayPal) {
      onClose();
      props.onPayPalSuccess(p)
    }
  }

  const handleError = (e: any) => {
    Analytics.trackEvent(category, 'payment_error', { mode: 'paypal' });
    if (withPayPal) {
      props.onPayPalError(e)
    }
  }

  return (
    <Modal
      isOpen={visible}
      className={classNames(classes.popup, { 
        [classes.dark]: isDark, 
        [classes.for_prepaid]: isPrepaidCard, 
      })}
      overlayClassName={classNames(classes.overlay, { [classes.dark]: isDark })}
    >
      <IconCancel onClick={handleClose} className={classes.close} />
      {errorPopup.isDetailedErrors && !withPayPal ?
        <Component onClick={onSubmit} />
        :
        <>
          <Image
            img={isDark ? images_dark[errorPopup?.type] : images[errorPopup?.type]}
            className={classes.img}
            alt='background'
          />
          <Title className={classes.title}>
            {t(`${tKey}.${code}.title`)}
          </Title>
          <Text className={classes.text}>
            <T k={`${tKey}.${code}.text`} />
          </Text>
          <>
            <Button
              onClick={onSubmit}
              flare={false}
              iconRight={false}
              className={classes.button}
              title={buttonTitle || t(`${tKey}.${code}.button`)}
            />
            {visible && withPayPal && product &&
              <div className={classes.paypal_wrap}>
                <div className={classes.or}>{t('core.payment_popup.or')}</div>
                <div className={classes.paypal}>
                  <PayPalBtn
                    onSuccess={handleSuccess}
                    onError={handleError}
                    product={product}
                    onSubmit={handlePayPalSubmit}
                  />
                </div>
              </div>
            }
            {onSkipClick && <LinkButton className={classes.button} onClick={onSkipClick}>
              {t(`${tKey}.${code}.button_later`)}
            </LinkButton>}
          </>
        </>
      }
    </Modal >
  )
};

export default ErrorPopup;
