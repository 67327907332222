import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserReport } from 'src/types/reports';

export interface ReportsState {
  reports: UserReport[];
}

const initialState: ReportsState = {
  reports: []
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<UserReport[]>) => {
      state.reports = action.payload;
    },
  }
})