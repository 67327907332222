import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from '@web-solutions/core/utils/normalize-blocks';

import { Flow } from 'src/constants/remote-config';
import { QUIZ_NAMES } from 'src/constants/screens';
import type { ChatFlow } from 'src/screens/chat-flow/types';

import { RootState } from '../types';

const ROUTES = Object.values(QUIZ_NAMES);

const getFlow = (state: any) => state.remoteConfig.flow;
const getChatFlow = (state: any) => state.remoteConfig.chatFlow;
const getSurveyLink = (state: any) => state.remoteConfig.quizSurvey?.link;
const getFlowWelcomeStep = (state: RootState) => state.remoteConfig.flowWelcomeStep;

export const selectFlowBlocks = createSelector(
  [getFlow, getSurveyLink, getFlowWelcomeStep],
  (flow, surveyLink, flowWelcomeStep): Flow[] => {
    let f = normalizeBlocks(flow) as Flow[];

    if (!surveyLink) {
      f = f?.map((arr) => arr.filter((item: any) => item !== QUIZ_NAMES.QUIZ_SURVEY))
    }

    f = f.map(i => _intersection(i, ROUTES)).filter(i => !!i.length);

    if (flowWelcomeStep) {
      f = f.map((item, indx) => {
        if (indx === 0) {
          return [flowWelcomeStep, ...item]
        }

        return item
      })
    }

    return f;
  },
);

export const selectFlow = createSelector(
  [selectFlowBlocks],
  (flow): Flow => {
    return flow.flat();
  }
);

export const selectChatFlow = createSelector(
  [getChatFlow],
  (flow): ChatFlow[] => {
    const chatFlow = flow?.flow

    return chatFlow ?? [];
  },
);