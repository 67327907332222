import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector, } from 'react-redux';
import classNames from 'classnames';

import { PaymentSystem } from '@web-solutions/react-billing';

import Analytics from '@web-solutions/module-analytics';

import { Progress } from '@web-solutions/base-app/components/upsales';

import { selectPaymentSystem } from 'core/store/billing/selectors';

import { ProductDetails } from 'core/store/billing/selectors';

import { Modal } from 'core/ui-elements';
import { type Subscription } from 'core/interfaces/billing';
import type { PaymentModalTitle } from 'core/interfaces/payment-modal';

import { PaymentFormOptions } from '../types';

import BasePaymentModalBody from './body';
import BasePaddleModalBody from './paddle';

import { ReactComponent as IconClose } from './icons/close-icon.svg';

import classes from './style.module.scss';
import './style.scss';

interface Props {
  activeProduct: ProductDetails,
  onClose: () => void,
  onSuccess: (purchase: Subscription) => void,
  onError: (error: any) => void,
  onSubmit: (formData: any) => Promise<void>,
  showPopup: boolean,
  options: PaymentFormOptions,
  showApplePay: boolean,
  showPayPal: boolean,
  showGooglePay: boolean,
  showMercadoPago: boolean,
  onApplePayClick: () => void,
  onApplePaySubmit: (formData: any) => Promise<void>,
  onGooglePayClick: () => void,
  onGooglePaySubmit: (formData: any) => Promise<void>,
  onPayPalSubmit: (formData: any) => Promise<void>,
  onPayPalSuccess: (p: Subscription) => void,
  onPayPalError: (e: any) => void,
  onUserInfoChange: (info: any) => void,
  onCheckPrepaid?: () => void,
  isOnlyBaseModal?: boolean,
  isOnlyCard?: boolean,
  fullMode?: boolean,
  title?: PaymentModalTitle,
  subtitle?: React.ReactNode,
  showCard?: boolean,
  isPurchaseProgress?: boolean,
  upsaleTrigger?: string,
  giftContent?: React.ReactNode,
}

const BasePaymentModal: React.FC<Props> = ({
  activeProduct,
  onClose,
  onSuccess,
  onError,
  onSubmit,
  isOnlyBaseModal,
  isOnlyCard,
  showPopup,
  options,
  showApplePay,
  showPayPal,
  showGooglePay,
  showMercadoPago,
  showCard,
  onApplePayClick,
  onApplePaySubmit,
  onGooglePayClick,
  onGooglePaySubmit,
  onPayPalSubmit,
  onPayPalSuccess,
  onPayPalError,
  onUserInfoChange,
  onCheckPrepaid,
  fullMode,
  title,
  subtitle,
  isPurchaseProgress,
  upsaleTrigger,
  giftContent
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);
  //@ts-ignore
  const isPending = useSelector((state) => state.billing.pending || state.billing.orderPending);
  //@ts-ignore
  const isSubmitByCard = useSelector((state) => state.billing.isSubmitByCard);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSubmitByCard) {
      ref.current?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
    }
  }, [isSubmitByCard]);

  useEffect(() => {
    if (showPopup) {
      Analytics.trackEvent('modal_payment', 'open', {
        productId: activeProduct?.id,
        paypalPlanId: activeProduct?.paypalPlanId,
        value: activeProduct?.firstPaymentAmount,
        currency: activeProduct?.currency,
      });

      ref.current?.scrollIntoView(true);
    }
  }, [showPopup, activeProduct]);

  useLayoutEffect(() => {
    if (showPopup) {
      document.body.classList.add('ReactModal__Body--open');
    } else {
      document.body.classList.remove('ReactModal__Body--open');
    }
  }, [showPopup]);

  return (
    <Modal
      isOpen
      className={classNames(classes.modalContainer, { [classes.fullMode]: fullMode }, { [classes.modalContainer_active]: isPending }, { [classes.hidden]: !showPopup })}
      overlayClassName={classNames({ [classes.hidden]: !showPopup })}
    >
      <div className={classes.innerContainer} ref={ref}>
        <br />
        <button type="button" onClick={onClose} className={classes.close}>
          <IconClose width="24px" height="24px" />
        </button>
        {isPurchaseProgress &&
          <div className={classes.process}>
            <Progress currentStep={'order'} />
          </div>
        }
        {paymentSystem === PaymentSystem.PADDLE
          ? (
            <BasePaddleModalBody
              subtitle={subtitle}
              activeProduct={activeProduct}
              options={options}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              onApplePayClick={onApplePayClick}
              onApplePaySubmit={onApplePaySubmit}
              onGooglePayClick={onGooglePayClick}
              onGooglePaySubmit={onGooglePaySubmit}
              onUserInfoChange={onUserInfoChange}
            />
          )
          : (
            <BasePaymentModalBody
              giftContent={giftContent}
              title={title}
              subtitle={subtitle}
              activeProduct={activeProduct}
              options={options}
              showApplePay={showApplePay}
              showPayPal={showPayPal}
              showGooglePay={showGooglePay}
              showMercadoPago={showMercadoPago}
              upsaleTrigger={upsaleTrigger}
              isOnlyBaseModal={isOnlyBaseModal}
              isOnlyCard={isOnlyCard}
              showCard={showCard}
              onSubmit={onSubmit}
              onSuccess={onSuccess}
              onError={onError}
              onApplePayClick={onApplePayClick}
              onApplePaySubmit={onApplePaySubmit}
              onGooglePayClick={onGooglePayClick}
              onGooglePaySubmit={onGooglePaySubmit}
              onPayPalSubmit={onPayPalSubmit}
              onPayPalSuccess={onPayPalSuccess}
              onPayPalError={onPayPalError}
              onUserInfoChange={onUserInfoChange}
              onCheckPrepaid={onCheckPrepaid}
            />
          )}
      </div>
    </Modal>
  );
};

export default BasePaymentModal;
